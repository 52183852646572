
import { IPet } from '@/interfaces';
import { dispatchGetPetsKindsChoices, dispatchGetBreedsChoices, dispatchGetMyPetsList, dispatchSaveMyPet } from '@/store/main/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class MyPetsTab extends Vue {
  public isEditing = false;
  public myPets = 'list';
  public petsList: IPet[] = [];
  public selectedPet: any = null;
  public petForm: any = {
    image: null,
    name: '',
    kind: null,
    age: 1,
    creature_breed: null,
    creature_breed_custom: '',
  };
  public kindsChoices: any = {
    choices: [],
  };
  public breedsChoices: any = {
    choices: [],
  };

  public async mounted() {
    this.petsList = await dispatchGetMyPetsList(this.$store);
    this.kindsChoices = await dispatchGetPetsKindsChoices(this.$store);
  }

  public handleAddPet(pet=null) {
    this.myPets = 'edit';
    this.selectedPet = pet;
    if (this.selectedPet) {
      this.petForm = {
        image: null,
        name: this.selectedPet.name,
        kind: this.selectedPet.creature_breed && this.selectedPet.creature_breed.kind ? this.selectedPet.creature_breed.kind : 'other',
        age: this.selectedPet.age,
        creature_breed: this.selectedPet.creature_breed,
        creature_breed_custom: this.selectedPet.creature_breed_custom,
      };
    }
    this.handleChangeKind(this.petForm.kind);
  }

  public handleBackToList() {
    this.myPets = 'list';
    this.selectedPet = null;
    this.petForm = {
      image: null,
      name: '',
      kind: null,
      age: 1,
      creature_breed: null,
      creature_breed_custom: '',
    };
  }
  
  public handleChangeImage(event) {
    this.petForm.image = event.target.files ? event.target.files[0] : '';
  }

  get getImageUrl() {
    if (!this.petForm.image && this.selectedPet && this.selectedPet.image) {
      return `background-image: url(${this.selectedPet.image});`;
    } else if (this.petForm.image) {
      return `background-image: url(${URL.createObjectURL(this.petForm.image)});`;
    } else {
      return '';
    }
  }

  public async handleChangeKind(kind) {
    this.petForm.kind = kind;
    if (this.petForm.kind !== 'other') {
      this.breedsChoices = await dispatchGetBreedsChoices(this.$store, {kind: this.petForm.kind});
    } else {
      this.breedsChoices = [];
      this.petForm.creature_breed = null;
    }
  }

  public generatePayload() {
    const formData = new FormData();
    if (this.selectedPet) {
      formData.append("id", this.selectedPet.id);
    }
    if (this.petForm.image) {
      formData.append("image", this.petForm.image);
    };
    formData.append("name", this.petForm.name);
    formData.append("age", this.petForm.age);
    formData.append("creature_breed", this.petForm.creature_breed ? this.petForm.creature_breed : '');
    formData.append("creature_breed_custom", this.petForm.creature_breed_custom);
    return formData;
  }

  public async handleSavePet() {
    if (!this.petForm.kind) {
      this.$toast.error('Нужно выбрать вид животного');
      return;
    } else if (this.petForm.kind !== 'other' && !this.petForm.creature_breed) {
      this.$toast.error('Нужно выбрать породу');
      return;
    } else if (this.petForm.kind == 'other' && !this.petForm.creature_breed_custom) {
      this.$toast.error('Нужно указать породу');
      return;
    }
    const payload = this.generatePayload();
    const result = await dispatchSaveMyPet(this.$store, payload);
    if (result) {
      this.petsList = await dispatchGetMyPetsList(this.$store);
      this.handleBackToList();
    } else {
      this.$toast.error('Ошибка');
    }
  }
  
  get creatureKindsList() {
    const creatureKindsList: any = [];
    if (this.kindsChoices && this.kindsChoices.choices) {
      for (const [key, value] of Object.entries(this.kindsChoices.choices)) {
        creatureKindsList.push({
          // @ts-ignore
          id: value[0], label: value[1],
        });
      }
    }
    return creatureKindsList;
  }
  get creatureBreedsList() {
    const creatureBreedsList: any = [];
    if (this.breedsChoices && this.breedsChoices.choices) {
      for (const [key, value] of Object.entries(this.breedsChoices.choices)) {
        creatureBreedsList.push({
          // @ts-ignore
          id: value[0], label: value[1],
        });
      }
    }
    return creatureBreedsList;
  }

}
