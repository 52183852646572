
import { Component, Vue } from 'vue-property-decorator';
import ChangePassword from '@/components/modals/ChangePassword.vue';
import MainHeader from '@/components/header/MainHeader.vue'
import { readUserProfile } from '@/store/main/getters';
import { dispatchUserEdit } from '@/store/main/actions';
import { IUserEdit } from '@/interfaces';
import moment from 'moment';
import { mask } from 'vue-the-mask';

@Component({
  directives: {mask},
    components: {
        ChangePassword,
        MainHeader,
    },
})

export default class EditProfileTab extends Vue {
  public userData : IUserEdit = {
    avatar_url:	'',
    avatar:	null,
    country: '',
    // user_type: '',
    // title: '',
    email: '',
    phone: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    region: '',
    city: '',
    nova_poshta_number: 0,
    // tg_notifications: false,
    // language: '',
    // fb_link: '',
    delivery_address: '',
    // site_link: '',
    // documents: '',
  };
  public isEditing = false;

  public async mounted() {
    if (this.userProfile) {
      this.userData.avatar_url = this.userProfile.avatar_165;
      this.userData.country = this.userProfile.country;
      // this.userData.user_type = this.userProfile.user_type;
      // this.userData.title = this.userProfile.title;
      this.userData.email = this.userProfile.email;
      this.userData.phone = this.userProfile.phone;
      this.userData.first_name = this.userProfile.first_name;
      this.userData.last_name = this.userProfile.last_name;
      this.userData.middle_name = this.userProfile.middle_name;
      this.userData.region = this.userProfile.region;
      this.userData.city = this.userProfile.city;
      this.userData.nova_poshta_number = this.userProfile.nova_poshta_number;
      this.userData.tg_notifications = this.userProfile.tg_notifications;
      this.userData.delivery_address = this.userProfile.delivery_address;
    }
  }

  public async generatePayload() {
    const formData = new FormData();
    if (this.userProfile) {
      // @ts-ignore
      formData.append('id', this.userProfile.id);
    }
    if (this.userData.avatar) {
      formData.append('avatar', this.userData.avatar);
    }
    // formData.append('user_type', this.userData.user_type);
    // formData.append('title', this.userData.title);
    formData.append('email', this.userData.email);
    formData.append('phone', this.userData.phone);
    formData.append('first_name', this.userData.first_name);
    formData.append('last_name', this.userData.last_name);
    formData.append('middle_name', this.userData.middle_name);
    // formData.append('country', this.userData.country);
    // formData.append('region', this.userData.region);
    // formData.append('city', this.userData.city);
    // @ts-ignore
    // formData.append('nova_poshta_number', this.userData.nova_poshta_number);
    // @ts-ignore
    // formData.append('tg_notifications', this.userData.tg_notifications);
    // @ts-ignore
    // formData.append('language', this.userData.language);
    // @ts-ignore
    // formData.append('fb_link', this.userData.fb_link);
    formData.append('delivery_address', this.userData.delivery_address);
    // @ts-ignore
    // formData.append('site_link', this.userData.site_link);
    // @ts-ignore
    // formData.append('documents', this.userData.documents);
    
    return formData;
  }

  public handleChangeImage(event) {
    this.userData.avatar = event.target.files ? event.target.files[0] : '';
  }

  public async handleSubmit() {
    // this.loadingState.edit = true;
    const payload = await this.generatePayload();
    const result = await dispatchUserEdit(this.$store, payload);
    if (result) {
      // this.$root.$i18n.locale = this.userData.language;
      // @ts-ignore
      this.$toast.success(this.$t('Saved successfully'), {
      duration: 3000, position: 'bottom-right',
      });
      this.isEditing = false;
      // this.$router.push('/my-cabinet');
    } else {
      // @ts-ignore
      this.$toast.error(this.$t('Save error'), {
      duration: 3000, position: 'bottom-right',
      });
    }
    // this.loadingState.edit = false;
  }

  get getImageUrl() {
    if (!this.userData.avatar && this.userProfile && this.userProfile.avatar_165) {
      return `background-image: url(${this.userProfile.avatar_165});`;
    } else if (this.userData.avatar) {
      return `background-image: url(${URL.createObjectURL(this.userData.avatar)});`;
    } else {
      return '';
    }
  }

  public handleEditing(){
    this.isEditing = true;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
