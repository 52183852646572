
import { IUserReferralCodesResponse } from '@/interfaces';
import { dispatchGenerateReferralCode, dispatchGetReferralCodesList, dispatchSaveMyDeliveryAddress } from '@/store/main/actions';
import { readUserProfile } from '@/store/main/getters';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ReferralCodesTab extends Vue {
  public codesResponse: IUserReferralCodesResponse = {
    count: 0,
    next: 0,
    previous: 0,
    total: 0,
    total_count: 0,
    results: [],
  };

  public page: number = 1;
  public loadingState: any = {
    submit: false,
    list: true,
  };

  public async mounted() {
    this.handleChangePage(1, true)
  }

  public async handleChangePage(page, force = false) {
    if (force || this.page != page) {
      this.page = page
      const rowQuery = `?page=${this.page}&page_size=10`
      this.codesResponse = await dispatchGetReferralCodesList(
        this.$store, {rowQuery}
      )
    }
    this.loadingState.list = false;
  }

  public async handleGenerateCode() {
    if (this.loadingState.submit) {
      return
    }
    // @ts-ignore
    this.$swal({
      title: this.$t('Ви точно хотите сгенерировать код?'),
      // icon: 'warning',
      buttons: {
        cancel: {
          text: this.$t('Нет'),
          value: false,
          visible: true,
          className: 'btn btn-primary',
          // closeModal: true,
        },
          confirm: {
          text: this.$t('Да'),
          value: true,
          visible: true,
          className: 'btn btn-primary',
          // closeModal: true,
        },
      },
    }).then(async (value) => {
      if (value) {
        this.loadingState.submit = true;
        const result = await dispatchGenerateReferralCode(this.$store, {});
        if (result) {
          this.$toast.success(this.$t('Код сгенерирован успешно').toString());
          this.handleChangePage(this.page, true)
        } else {
          this.$toast.error(this.$t('Ошибка при создании кода').toString());
        }
        this.loadingState.saveAddress = false;
      }
    })
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
