
import { INovaPoshtaCity, IUserDeliveryAddress } from '@/interfaces';
import { dispatchGetMyDeliveryAddressesList, dispatchGetNovaPoshtaCities, dispatchSaveMyDeliveryAddress } from '@/store/main/actions';
import { readUserProfile } from '@/store/main/getters';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class MyDeliveryAddressesTab extends Vue {
  public citiesChoices: INovaPoshtaCity[] = [];
  public deliveryAddressesList: IUserDeliveryAddress[] = [];
  public addressForm: boolean = false;
  public selectedAddress: any = null;
  public deliveryAddress: any = {
    city_code_np: '',
    city: '',
    street: '',
    house_number: '',
    apartment_number: '',
  };
  public citySearch: string = '';
  public loadingState: any = {
    saveAddress: false,
  };

  public async mounted() {
    this.deliveryAddressesList = await dispatchGetMyDeliveryAddressesList(this.$store, {editable: true});
    this.citiesChoices = await dispatchGetNovaPoshtaCities(this.$store, {search: this.citySearch});
  }

  public handleEditAddress(address: IUserDeliveryAddress) {
    this.addressForm = true;
    this.selectedAddress = address;
    this.deliveryAddress = {
      city_code_np: this.selectedAddress.city_code_np,
      city: this.selectedAddress.city ? this.selectedAddress.city : '',
      street: this.selectedAddress.street,
      house_number: this.selectedAddress.house_number,
      apartment_number: this.selectedAddress.apartment_number ? this.selectedAddress.apartment_number : '',
    };
    this.handleSearchByKeyword(this.deliveryAddress.city, false);
  }

  public async handleChangeCity(event) {
    this.deliveryAddress.city = '';
    for (let i in this.citiesChoices) {
      if (this.citiesChoices[i].DeliveryCity === event) {
        this.deliveryAddress.city = this.citiesChoices[i].Present;
        return;
      }
    }
  }

  public async handleSearchByKeyword(search, loading) {
    this.citySearch = search;
    if (this.citySearch !== '') {
      this.citiesChoices = await dispatchGetNovaPoshtaCities(this.$store, {search: this.citySearch});
    }
  }

  public async handleSaveAddress() {
    if (this.deliveryAddress.city_code_np && this.deliveryAddress.street && this.deliveryAddress.house_number) {
      this.loadingState.saveAddress = true;
      const formData = new FormData();
      if (this.selectedAddress) {
        formData.append("id", this.selectedAddress.id);
      }
      formData.append("city_code_np", this.deliveryAddress.city_code_np);
      formData.append("city", this.deliveryAddress.city);
      formData.append("street", this.deliveryAddress.street);
      formData.append("house_number", this.deliveryAddress.house_number);
      formData.append("apartment_number", this.deliveryAddress.apartment_number);
      const response = await dispatchSaveMyDeliveryAddress(this.$store, formData);
      if (response) {
        this.deliveryAddressesList = await dispatchGetMyDeliveryAddressesList(this.$store, {});
      }
      this.addressForm = false;
      this.selectedAddress = null;
      this.deliveryAddress = {
        city_code_np: '',
        city: '',
        street: '',
        house_number: '',
        apartment_number: '',
      };
      this.loadingState.saveAddress = false;
    } else {
      console.log(this.deliveryAddress);
    }
  }

  get citiesList() {
    let citiesList: any = [];
    for (let i in this.citiesChoices) {
      citiesList.push({
        id: this.citiesChoices[i].DeliveryCity,
        label: this.citiesChoices[i].Present,
      });
    }
    return citiesList;
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
