
import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetMyPersonalDiscounts } from '@/store/special_offers/actions';
import { readPersonalDiscountsResponse } from '@/store/special_offers/getters';
import { dispatchChangeUser1cAdress } from '@/store/main/actions';
import { dispatchGetRecentInvoiceDetails } from '@/store/products/actions';
import { getLocalSessionId } from '@/utils';
import { commitSetProductsInvoice } from '@/store/products/mutations';

@Component({
  components: {},
})

export default class DiscountsTab extends Vue {
  public bonusAdress: any = null;
  public loadingAdress: boolean = false;

  public async mounted() {
    await dispatchGetMyPersonalDiscounts(this.$store);
    if (this.userProfile?.id_from_1c) {
      this.bonusAdress = this.userProfile.id_from_1c;
    }
  }

  public async handleChangeBonusAdress() {
    if ( confirm(this.$t('Изменить бонусную карту?').toString()) ) {
      this.loadingAdress = true;
      await dispatchChangeUser1cAdress(this.$store, {uid: this.bonusAdress});
      this.loadingAdress = false;
      await dispatchGetMyPersonalDiscounts(this.$store);
      const response = await dispatchGetRecentInvoiceDetails(this.$store, {anon_session_id: getLocalSessionId(),});
      if (response) {
        commitSetProductsInvoice(this.$store, response);
      }
    } else {
      if (this.userProfile?.id_from_1c) {
        this.bonusAdress = this.userProfile.id_from_1c;
      }
    }
  }

  get discountsResponse() {
    return readPersonalDiscountsResponse(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get bonusAdressesList() {
    let result: any[] = [];
    if (this.userProfile && this.userProfile.addresses_from_1c) {
      for (let i in this.userProfile.addresses_from_1c) {
        result.push({
          id: this.userProfile.addresses_from_1c[i].uid,
          label: this.userProfile.addresses_from_1c[i].adress,
        });
      }
    }
    return result;
  }
}
