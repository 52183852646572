
import { dispatchGetCategoriesSubscriptionsList } from '@/store/products/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class SubscribedCategoriesTab extends Vue {
  public perPage = 20;
  public page = 1;
  public loadingState: any = {
    list: false,
    page: true,
  };
  public categoriesResponse: any = {
    count: 0,
    next: 0,
    previous: 0,
    total: 0,
    total_count: 0,
    results: [],
  };

  public async mounted() {
    this.handleChangePage(1, true);
    this.loadingState.page = false;
  }

  public async handleChangePage(page, force = false) {
    if (this.page != page || force) {
      this.loadingState.list = true;
      this.page = page;
      const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
      this.categoriesResponse = await dispatchGetCategoriesSubscriptionsList(
        this.$store, {data: {}, rowQuery},
      );
      this.loadingState.list = false;
    }
  }

  public handleChangeCategory(category) {
    this.$router.push(`/catalog/${category.codename}`);
  }
}
