
import { IInvoicesListResponse } from '@/interfaces/products';
import { readIsLoggedIn } from '@/store/main/getters';
import { dispatchGetOrdersList } from '@/store/products/actions';
import { dispatchGetTestimonialsList } from '@/store/testimonials/actions';
import { readTestimonialsResponse } from '@/store/testimonials/getters';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class MyOrdersTab extends Vue {
  @Prop({required: false, default: null}) public user_id!: number | null;

  public page: number = 1;
  public perPage: number = 3;
  public rating: number = 5;
  public showReviewSidebar: boolean = false;

  public mounted() {
    this.handleChangePage(1, true);
  }

  public async handleChangePage(page, force = false) {
    this.page = page;
    const rowQuery = `?page=1&page_size=${this.page * this.perPage}`;
    await dispatchGetTestimonialsList(
      this.$store, {
        data: {
          user_id: this.user_id,
        }, rowQuery,
      },
    );
  }

  public handleUpdateReviews() {
    this.showReviewSidebar = false;
    this.handleChangePage(this.page);
  }

  get testimonialsResponse() {
    return readTestimonialsResponse(this.$store);
  }
  get isLoggedIn() {
    return readIsLoggedIn(this.$store);
  }

}
