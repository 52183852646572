
import { IInvoicesListResponse } from '@/interfaces/products';
import { dispatchGetOrdersList } from '@/store/products/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class MyOrdersTab extends Vue {
  public page = 0;
  public filters: any = {
    search: '',
    period: null,
  };
  public ordersResponse: IInvoicesListResponse = {
    count: 0,
    next: 0,
    previous: 0,
    total: 0,
    total_count: 0,
    results: [],
  };
  // public orderHistoryStep = 1;
  public orderInfo = '';
  public openOrderInfo = false;

  public mounted() {
    this.handleChangePage(1, true);
  }

  public async handleChangePage(page, force=false) {
    if (this.page != page || force) {
      this.page = page;
      const rowQuery = `?page=${this.page}`;
      this.ordersResponse = await dispatchGetOrdersList(this.$store, {data: this.filters, rowQuery});
    }
  }

  public async handleSearchByPhrase() {
    this.handleChangePage(1, true);
  }

  public handleOpenOrderInfo(order) {
    this.orderInfo = order;
    this.openOrderInfo = true;
  }

  public handleCloseOrderInfo() {
    this.openOrderInfo = false;
    this.orderInfo = '';
  }

  // public openOrderHistoryInfo(id) {
  //   this.orderHistoryStep = 2;
  // }

  get periodOptions() {
    return [
      { value: null, text: this.$t('За все время') },
      { value: '7_days', text: this.$t('Неделя') },
      { value: '30_days', text: this.$t('Месяц') },
      { value: 'year', text: this.$t('Год') },
    ];
  }

}
